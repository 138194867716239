import React, { useEffect, useState } from "react";
import PropertyApi from "../../api/PropertyApi";
import { HOME_ITEMS } from "../../components/home-with-stats/HomeWithStats";
import { Config } from "../../Config";
import { PropertyFilterParams, PropertyItem } from "../../api/PropertyTypes";
import { SortingConfig } from "../../interfaces/SortingConfig";
import { PropertyList } from "../../components/property-list/PropertyList";
import { SideNavigation } from "../../components/side-navigation/SideNavigation";
import { PropertyGrid } from "../../components/property-grid/PropertyGrid";

export function FavoritesHome() {
  const [properties, setProperties] = useState<PropertyItem[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sortingConfig, setSortingConfig] = useState<SortingConfig>();
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    PropertyApi.list(1, Config.getDefaultPageSize(), {
      favouritesOnly: "true",
    } as PropertyFilterParams)
      .then((p) => {
        setLoading(false);
        setProperties(p.data);
        setTotalPages(Math.ceil(p.count / Config.getDefaultPageSize()));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function retrievePage(page: number) {
    if (Number.isNaN(page)) {
      return;
    }

    PropertyApi.listPersonalProperties(
      page,
      Config.getDefaultPageSize(),
      sortingConfig
    ).then((p) => {
      setProperties(p.data);
      setTotalPages(Math.ceil(p.count / Config.getDefaultPageSize()));
    });
  }

  return (
    <>
      <div className={"page personal-home"}>
        <div className={"side-nav"}>
          <SideNavigation items={HOME_ITEMS} />
        </div>
        <div className="page-content-no-margin">
          {windowSize[0] > 1350 ? (
            <PropertyList
              properties={properties}
              retrievePage={retrievePage}
              setSortingConfig={setSortingConfig}
              totalPages={totalPages}
              hideAddButton={true}
              loading={loading}
              showRegisteredClients={false}
            />
          ) : (
            <PropertyGrid
              properties={properties}
              retrievePage={retrievePage}
              totalPages={totalPages}
              loading={loading}
            />
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import "./Select.css";
import { ReactComponent as ChevronDown } from "../../assets/chevron-down-icon.svg";

export function Select({
  className,
  label,
  value,
  onChange,
  required = true,
  options,
  showAsterisk,
  clean = false,
}: {
  className?: string;
  label: string | null;
  value: string | undefined;
  required?: boolean;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  showAsterisk?: boolean;
  clean?: boolean;
}) {
  return (
    <div
      className={`select-group flex ${clean ? "clean" : ""} ${
        className ? className : ""
      }`}
    >
      <select
        className={`select-field mt-2 ${clean ? "clean" : ""}`}
        value={value}
        required={required}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {label ? (
        <label className="label">
          {label} {required && showAsterisk ? "*" : ""}
        </label>
      ) : null}
      <ChevronDown className={"chevron-down"} />
    </div>
  );
}

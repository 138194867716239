import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropertyApi from "../../api/PropertyApi";
import { LuRefreshCcw, LuSearch } from "react-icons/lu";
import { FilterName } from "../../enums/FilterName";
import { PropertyFilter } from "./PropertyFilter";
import "./PropertyFilters.css";
import { getFilterParamsFromUrl } from "../../util/filters";
import { FilterCombinations } from "./FilterCombinations";
import FilterCombinationApi, {
  FilterCombination,
} from "../../api/FilterCombinationApi";
import { Button } from "../button/Button";
import { Config } from "../../Config";
import { PropertyFilterParams, PropertyItem } from "../../api/PropertyTypes";

export function PropertyFilters({
  className,
  setProperties,
  setTotalPages,
}: {
  className: string;
  setProperties: Dispatch<SetStateAction<PropertyItem[]>>;
  setTotalPages: (value: number) => void;
}) {
  const { t } = useTranslation();

  const [filterParams, setFilterParams] = useState<PropertyFilterParams>(
    getFilterParamsFromUrl()
  );

  const [filterCombinations, setFilterCombinations] = useState<
    FilterCombination[]
  >([]);

  function onClickSearch(updatedFilterParams?: PropertyFilterParams) {
    const params = updatedFilterParams ? updatedFilterParams : filterParams;
    PropertyApi.list(1, Config.getDefaultPageSize(), params).then((p) => {
      setProperties(p.data);
      setTotalPages(Math.ceil(p.total / Config.getDefaultPageSize()));
    });

    // update url with filter params
    const url = new URL(window.location.href);
    Object.entries(params).forEach(([key, value]) => {
      if (value === undefined) {
        url.searchParams.delete(key);
        return;
      }

      url.searchParams.set(key, value);
    });
    window.history.replaceState({}, "", url.toString());
  }

  function onClickReset() {
    PropertyApi.list(1, Config.getDefaultPageSize()).then((p) => {
      setProperties(p.data);
    });

    const url = new URL(window.location.href);
    Object.entries(filterParams).forEach(([key]) => {
      url.searchParams.delete(key);
    });
    window.history.replaceState({}, "", url.toString());

    setFilterParams(getFilterParamsFromUrl());
  }

  useEffect(() => {
    FilterCombinationApi.getFilterCombinations().then((filterCombinations) => {
      setFilterCombinations(filterCombinations);
    });
  }, []);

  return (
    <div className={`filters-outer-wrapper ${className}`}>
      <div className="filters-wrapper">
        <FilterCombinations
          filterCombinations={filterCombinations}
          setFilterCombinations={setFilterCombinations}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          reset={onClickReset}
          search={onClickSearch}
        />
        <div className={`property-filters ${className}`}>
          <div className="flex">
            <Button
              className="search-button mr-auto small-button"
              onClick={onClickReset}
            >
              <span className="button-text">{t("filters.reset")}</span>
              <LuRefreshCcw />
            </Button>
            <Button
              className="search-button ml-auto  small-button"
              onClick={() => onClickSearch()}
            >
              <span className="button-text">{t("filters.search")}</span>
              <LuSearch />
            </Button>
          </div>
          <PropertyFilter
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            filterName={FilterName.FavouritesOnly}
          />
          <PropertyFilter
            filterName={FilterName.Region}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.GeneralType}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.Bedrooms}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.Bathrooms}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.Price}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.InteriorSurface}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.TotalSurface}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.TerraceSurface}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.Orientation}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.Garage}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.Pool}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.Garden}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <PropertyFilter
            filterName={FilterName.Agency}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
          />
          <div className="flex">
            <Button
              className="search-button mr-auto small-button"
              onClick={onClickReset}
            >
              <span className="button-text">{t("filters.reset")}</span>
              <LuRefreshCcw />
            </Button>
            <Button
              className="search-button ml-auto small-button"
              onClick={() => onClickSearch()}
            >
              <span className="button-text">{t("filters.search")}</span>
              <LuSearch />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

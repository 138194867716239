import React, { useEffect, useState } from "react";
import { SideNavigation } from "../../components/side-navigation/SideNavigation";
import { useTranslation } from "react-i18next";
import AgencyApi, { Agency } from "../../api/AgencyApi";
import { Input } from "../../components/input/Input";
import { toast } from "react-toastify";
import { SETTINGS_ITEMS } from "../../constants/SettingsItems";
import { Button } from "../../components/button/Button";
import { isXmlFeedUrl } from "../../util/isXmlFeedUrl";

export function InmobaliaSettings() {
  const { t } = useTranslation();

  const [xmlFeedUrl, setXmlFeedUrl] = useState("");
  const [agency, setAgency] = useState<Agency | undefined>(undefined);

  useEffect(() => {
    AgencyApi.getCurrent().then((result) => {
      setAgency(result);
      setXmlFeedUrl(result?.inmobaliaXmlFeedUrl ?? "");
    });
  }, []);

  return (
    <>
      <div className={"page settings"}>
        <div className={"side-nav"}>
          <SideNavigation items={SETTINGS_ITEMS} />
        </div>
        <div className="page-content-no-margin">
          <div className={"title"}>{t("sideNavigation.inmobalia")}</div>
          <div>
            <div className="mb-4">
              <Input
                value={xmlFeedUrl}
                onChange={setXmlFeedUrl}
                placeholder={t("settings.xmlFeedUrl")}
                required={true}
                type={"text"}
              />
            </div>
            <Button
              onClick={async () => {
                if (!agency) {
                  return;
                }

                if (!xmlFeedUrl) {
                  return;
                }

                if (!isXmlFeedUrl(xmlFeedUrl)) {
                  toast.error(t("settings.useValidFeedUrl"));
                  return;
                }

                await toast.promise(
                  AgencyApi.updateInmobaliaXmlFeedUrl(xmlFeedUrl),
                  {
                    pending: t("settings.updatingXmlFeedUrl"),
                    success: t("settings.xmlFeedUrlUpdated"),
                    error: t("settings.xmlFeedUrlFailed"),
                  },
                  { toastId: "updateInmobaliaXmlFeedUrl", autoClose: 5000 }
                );
              }}
            >
              {t("settings.update")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
